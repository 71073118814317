import React from "react";
import { saveAs } from "file-saver";
import "./ClientsStyles.css";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Table,
  Modal,
  Spin,
  Input,
} from "antd";
import { bindActionCreators } from "redux";
import {
  sendDynamicLinkEmail,
  getPDF,
  getPDFInvoicePayment,
  checkDuplicateClientInvoice,
  editInvoice,
  sendDynamicLinkEmailBlob,
  getEmailContent,
} from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import activityData from "../Components/ClientActivity/ActivityData";
import { apiRefresh } from "../services/api";
import FroalaEditorCom from "../Containers/FroalaEditorCom";
import { getFilterList } from "../Common/getEmailContentList";

const { Option } = Select;

const layoutSendingReceipt = {
  labelCol: { span: 8, offset: 0 },
  wrapperCol: { span: 20, offset: 2 },
};

class SendEmail extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    super(props);
    this.state = {
      sendingReceiptEmail: "",
      loading: false,
      resultList: [],
    };
  }

  componentWillMount() {
    this.props &&
      this.props.onGetEmailContent().then((res) => {
        let resultList = getFilterList(res);
        this.setState({ resultList: resultList });
      });
  }

  componentDidMount() {
    let _emails =
      this.props.clientProfileData &&
      this.props.clientProfileData.emails &&
      this.props.clientProfileData.emails;
    let setEmailData = this.props.setEmailData;

    this.setState({
      sendingReceiptEmail: setEmailData && setEmailData.htmlTemplate,
    });
    let toEmail;
    if (_emails && _emails.length > 0) {
      for (let index = 0; index < _emails.length; index++) {
        if (this.props && this.props.showSecondaryEmail === true) {
          if (_emails[index].emailTypeId === 2) {
            toEmail = _emails[index].address;
          }
        } else {
          if (_emails[index].emailTypeId === 1) {
            toEmail = _emails[index].address;
          }
        }
      }
    }
    let clientData = JSON.parse(sessionStorage.getItem("profileData"));
    this.formRef.current.setFieldsValue({
      from: localStorage.getItem("selectedBranchName"),
      to:
        this.props && this.props.clientEmail
          ? this.props.clientEmail
          : toEmail || clientData.primaryEmail,
      subject:
        this.props && this.props.showInvoiceTitle
          ? `Invoice # ${this.props.invoiceNo} from ${this.props.branchName}`
          : `Receipt for Invoice # ${this.props.invoiceNo} from ${this.props.branchName}`,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {}

  onFinishSendingReceipt = async (values) => {
    let _from = values.from === undefined ? "" : values.from;
    let _to = values.to === undefined ? "" : values.to;
    let _cc = values.cc === undefined ? "" : values.cc;
    let _bcc = values.bcc === undefined ? "" : values.bcc;
    let _subject = values.subject === undefined ? "" : values.subject;
    const formData = new FormData();
    formData.append("Message", this.state.sendingReceiptEmail);
    formData.append("Subject", _subject);
    formData.append("From", _from);
    formData.append("Priority", "10");
    formData.append("FrequencyCode", "client");
    formData.append("ClientId", this.props.clientId);
    formData.append("ModuleId", "10");
    formData.append(`Recipients[0].name`, _to);
    formData.append(`Recipients[0].type`, "To");
    if (_cc !== "") {
      formData.append(`Recipients[1].name`, _cc);
      formData.append(`Recipients[1].type`, "CC");
    }
    if (_bcc !== "") {
      formData.append(`Recipients[2].name`, _bcc);
      formData.append(`Recipients[2].type`, "Bcc");
    }
    formData.append(
      `Attachments[0].type`,
      this.props && this.props.attDataArray && this.props.attDataArray[0].type
    );
    formData.append(
      `Attachments[0].blobURL`,
      this.props && this.props.attDataArray && this.props.attDataArray[0].url
    );
    formData.append(
      `Attachments[0].name`,
      this.props && this.props.attDataArray && this.props.attDataArray[0].name
    );
    let clientprofileid = sessionStorage.getItem("clientprofileid");

    const draftData = {
      id: 0,
      emailMessage: this.state.sendingReceiptEmail || "",
      isRead: true,
      attachmentName:
        this.props &&
        this.props.attDataArray &&
        this.props.attDataArray[0].name,
      attachmentUrl:
        this.props && this.props.attDataArray && this.props.attDataArray[0].url,
      sizeInKB: 0,
      subject: _subject,
      from: localStorage.getItem("selectedBranchName"),

      import: false,
      to: _to,
      cc: _cc,
      bcc: _bcc,
      notClients: false,
      clientReply: false,
      clientReplyMail: 0,
      importMessageId: "",
      draft: false,
      emailType: 0,
      importText: "",
      clientId: clientprofileid && clientprofileid,
      importedDate: new Date(),
      autoImport: false,
      isSent: true,
      clientEmailType: 0,
      timeStamp: new Date(),
    };

    const histOpt = {
      url: `v1/imap/ClientEmail`,
    };
    histOpt.types = [
      "EMAIL_QUEUE_WITH_BLOB_SUCCESS",
      "EMAIL_QUEUE_WITH_BLOB_FAILURE",
    ];
    this.setState({ loading: true });

    apiRefresh.post(histOpt, draftData).then((res) => {
      formData.append(`emailId`, res);

      this.props
        .sendDynamicLinkEmail(formData)
        .then(async () => {
          message.success("Email sent successfully!");
          let _invoiceInfo = this.props && this.props.invoiceInfo;
          let _statusId;
          if (
            _invoiceInfo &&
            _invoiceInfo.invoiceTypeId === 2 &&
            _invoiceInfo.statusId === 27
          ) {
            _statusId = _invoiceInfo.statusId;
          } else {
            if (
              this.props.state.dueAmount !== 0 &&
              this.props.state.dueAmount > 0
            ) {
              if (
                this.props.state.invoiceStatuses &&
                this.props.state.invoiceStatuses.length > 0
              ) {
                _statusId = this.props.state.invoiceStatuses[1].id;
              }
            } else {
              _statusId = this.props.state.invoiceStatuses[5].id;
            }
          }

          await this.props.getSentStatus(_statusId);

          this.props
            .editInvoice(this.props.state)
            .then(() => {
              message.success("Invoice is updated successfully!");
              this.props.handleCancelSendingReceiptModal();
              var profileData = JSON.parse(this.props.profileDataRdx);

              let myData = {
                clientName:
                  this.props && this.props.clientName
                    ? this.props.clientName
                    : profileData && profileData.fullName,
                logMessage:
                  "Invoice " +
                  this.props.invoiceNo +
                  " updated and sent via email to client :" +
                  ' <Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer;" onclick={this.nextSibling.style.display="inline-block";this.nextSibling.nextSibling.style.display="block";this.style.display="none";this.nextSibling.nextSibling.childNodes[0].style.color="#1280b8";this.nextSibling.nextSibling.childNodes[0].style.fontSize="10px"}>Detail</Button>' +
                  '<Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer; display: none" onclick={this.nextSibling.style.display="none";this.style.display="none";this.previousSibling.style.display="inline-block"}>Hide</Button>' +
                  '<div style="display: none">' +
                  this.state.sendingReceiptEmail +
                  "</div>",
                date: moment(new Date()).format("DD/MM/YYYY"),
                logType: "Client Invoice",
                invoiceId: "0",
              };
              activityData(myData);
              this.setState({ loading: false });
            })
            .catch(() => {
              this.setState({ loading: false });
            });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  };

  onClickPDFFile = () => {
    this.setState({ loading: true });
    let _data = {
      id: this.props && this.props.invoiceId,
      paymentId: this.props && this.props.paymentId,
    };
    if (this.props && this.props.isPDFRelatedToInvoice === true) {
      this.props
        .getPDF(_data)
        .then((response) => {
          let blob = new Blob([response.payload], {
            type: "application/octet-stream",
          });
          this.setState({ loading: false });
          saveAs(blob, `Invoice-${this.props.invoiceNo}.pdf`);
        })
        .catch((e) => {
          this.setState({ loading: false });
          message.error("Unable to download, please try again!");
        });
    } else {
      this.props
        .getPDFInvoicePayment(_data)
        .then((response) => {
          let blob = new Blob([response.payload], {
            type: "application/octet-stream",
          });
          this.setState({ loading: false });
          saveAs(blob, `Receipt-${this.props.invoiceNo}.pdf`);
        })
        .catch((e) => {
          this.setState({ loading: false });
          message.error("Unable to download, please try again!");
        });
    }
  };

  getSendingReceiptForm = (props) => {
    return (
      <div className="border-box-modal-sending-receipt add-employer-para">
        <Row>
          <Col xs={3} offset={21}>
            <div
              className="pdf-file-btn"
              style={{ backgroundColor: "#f0ad4e" }}
            >
              <a className="pdf-file-text" onClick={this.onClickPDFFile}>
                {this.props && this.props.showInvoiceTitle
                  ? `INVOICE-${this.props.invoiceNo}-.PDF`
                  : `RECEIPT-${this.props.invoiceNo}-.PDF`}
              </a>
            </div>
          </Col>
        </Row>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="From"
          name="from"
          rules={[
            { required: true, message: "Required!" },
            // { type: "email", message: "Invalid!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="To"
          name="to"
          rules={[
            { required: true, message: "Required!" },
            { type: "email", message: "Invalid!" },
          ]}
        >
          <Input placeholder="Enter Email" />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="CC"
          name="cc"
          rules={[{ type: "email", message: "Invalid!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Bcc"
          name="bcc"
          rules={[{ type: "email", message: "Invalid!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Subject"
          name="subject"
          rules={[{ required: true, message: "Required!" }]}
        >
          <Input />
        </Form.Item>
        <Row>
          <Col xs={8} style={{ marginTop: "3vh", marginBottom: "4vh" }}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Select
              placeholder={"Select content type"}
              virtual={false}
              onChange={(value) => {
                let _emailTemplatePayload = {
                  templateName: value,
                  // templateName: "invoicefu",
                  parameters: [
                    {
                      key: "ClientId",
                      value: this.props.stateValue.clientProfileId,
                    },
                    {
                      key: "CurrencyId",
                      value: this.props.stateValue.currencyId
                        ? this.props.stateValue.currencyId.toString()
                        : "106",
                    },
                    {
                      key: "UserId",
                      value: this.props.stateValue.userId,
                    },
                    {
                      key: "Content",
                      value: value,
                    },
                    {
                      key: "Amount",
                      value: this.props.stateValue.dueAmount.toString(),
                    },
                    {
                      key: "InvoiceNumber",
                      value: this.props.stateValue.invoiceNo,
                    },
                    {
                      key: "DueDate",
                      value: this.props.stateValue.dueDate,
                    },
                  ],
                };
                this.setState({ loading: true });
                this.props
                  .setEmailTemplate(_emailTemplatePayload)
                  .then((res) => {
                    this.setState({
                      sendingReceiptEmail: res.payload.htmlTemplate,
                      loading: false,
                    });
                  })
                  .catch((err) => this.setState({ loading: false }));
              }}
            >
              <Option value={""}>{"Select"}</Option>
              {this.state &&
                this.state.resultList.map((item, index) => (
                  <Option value={item.emailType}>{item.emailType}</Option>
                ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <FroalaEditorCom
              model={this.state.sendingReceiptEmail}
              onModelChange={this.getEditorValue}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={2} offset={20} style={{ display: "flex" }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "10px" }}
                className="button-blue"
              >
                Send
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                onClick={props.handleCancelSendingReceiptModal}
                className="button-blue"
              >
                Close
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  getEditorValue = (value) => {
    this.setState({ sendingReceiptEmail: value });
  };

  render() {
    let a = this.props;

    return (
      <div className="reminder-model">
        <Modal
          className="reminder-model-main width-modal-sending-receipt"
          title="SEND EMAIL NOTIFICATION"
          visible={this.props.visibleSendingReceiptModal}
          onCancel={this.props.handleCancelSendingReceiptModal}
          footer={null}
          maskClosable={false}
        >
          <Spin size="large" spinning={this.state.loading}>
            <Form
              {...layoutSendingReceipt}
              onFinish={this.onFinishSendingReceipt}
              ref={this.formRef}
            >
              {this.getSendingReceiptForm(this.props)}
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendDynamicLinkEmail: bindActionCreators(sendDynamicLinkEmailBlob, dispatch),
  getPDF: bindActionCreators(getPDF, dispatch),
  getPDFInvoicePayment: bindActionCreators(getPDFInvoicePayment, dispatch),
  editInvoice: bindActionCreators(editInvoice, dispatch),
  onGetEmailContent: bindActionCreators(getEmailContent, dispatch),
});
SendEmail = connect(mapStateToProps, mapDispatchToProps)(SendEmail);

export default withRouter(SendEmail);
